/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.scss in this case)
import './styles/app.scss';


const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');
$(document).ready(function() {
    /*
    Shrink
    $(window).scroll(function () {
        var $unstick = $('body.unstick-menu');
        if (!$unstick.length) {
            var $headerdiv = $('.site-header');
            if ($headerdiv.length) {
                var header = $('.site-header').outerHeight();
            } else {
                var header = 1;
            }
            if ($(document).scrollTop() > (header + 60)) {
                $('nav#site-navigation').addClass('shrink');
            } else {
                $('nav#site-navigation').removeClass('shrink');
            }
        }
    });
    */

    $(window).scroll(function(e){
        if ($(window).width() > 800) {

            $('.header-cover').css({
                'bottom' : -($(this).scrollTop()/3)+"px",
                // 'margin-bottom': ($(this).scrollTop()/3)+"px"
            });
            var thisdist = $(this).scrollTop();
            var headerheight = $(".header-cover").outerHeight();
            $('.header-inner').css({
                'opacity' : (1 - thisdist/headerheight)
            });
        } else {
            $('.header-cover').css({'bottom' : 'auto'});
            $('.header-inner').css({'opacity' : "1" });
        }
    });



    if($('#carousel').length > 0) {
        var apply = false;
        var minAutoResize = 768;
        var autoResize = 1158;
        var carousel = function(element) {
            var position = 0;
            var that = this;
            var max = element.children('#carousel-list').children('.carousel-detail').length;
            var size = getWidth();
            this.position = function(pos) {
                position = pos;
                this.applyPosition();
            }
            this.applyPosition = function(animate = true) {
                let size = $('#carousel').innerWidth();
                if(animate) {
                    element.children('#carousel-list').animate({left: '-'+(position * size)+'px'});
                }
                else {
                    element.children('#carousel-list').css({left: '-'+(position * size)+'px'});
                }
                $('#carousel-position a').removeClass('active');
                $('#carousel-position a[data-position='+position+']').addClass('active');
            }
            this.next = function() {
                if(position >= max - 1) {
                    position = 0 ;
                }
                else {
                    position ++;
                }
                this.position(position);
            }
            this.prev = function() {
                if(position <= 0) {
                    position = max - 1;
                }
                else {
                    position --;
                }
                this.position(position);
            }
            this.sizeChange = function(value) {
                size = value;
                element.children('#carousel-list').css('left',  '-'+(size * position)+'px');
            }
            this.applySize = function(width) {
                let size = $('#carousel').innerWidth();
                $('.carousel-detail').css('width', size+'px');
            }
            this.applySize(size);
        }

            var car = new carousel($('#carousel'));

            $('#carousel-next').on('click', function(e) {
                e.preventDefault();
                car.next();
            })
            $('#carousel-prev').on('click', function(e) {
                e.preventDefault();
                car.prev();
            })
            $('#carousel-position a').on('click', function(e) {
                e.preventDefault();
                car.position($(this).data('position'));
            })
        let oldSize = $('#carousel').innerWidth();
            $(window).resize(function () {
                let newSize =  $('#carousel').innerWidth();
                console.log('--------')
                console.log(oldSize);
                console.log(newSize);
                if(oldSize != newSize) {
                    oldSize = newSize;
                    console.log('APPLYYYYYYYYYYYYYYY')
                    car.applySize();
                    if(newSize > 500) {
                        car.applyPosition(false)

                    }
                }
            })

            $(document).keyup(function(e) {
                if(e.which == 39) {
                    car.next();
                }
                else if(e.which == 37) {
                    car.prev();
                }
            });

        function getWidth() {
            var width = $( window ).width();
            if(width > autoResize) {
                return autoResize + 1;
            }
            else if(width < 800) {
                return 799;
            }
            else {
                return width;
            }
        }
    }
});
